<template>
    <div class="interfaceUser-addPop">
        <el-form
            ref="ruleForm"
            :model="form"
            :rules="rules"
            label-width="150px"
            status-icon
        >
            <el-form-item label="用户名" prop="login_name">
                <el-input v-model="form.login_name"></el-input>
            </el-form-item>
            <el-form-item label="中文名" prop="user_name">
                <el-input v-model="form.user_name"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input type="password" v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
                <el-input type="password" v-model="form.checkPass"></el-input>
            </el-form-item>
            <el-form-item label="电话">
                <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="IP范围">
                <el-input type="textarea" v-model="form.ip_range"></el-input>
            </el-form-item>
            <el-form-item label="描述">
                <el-input type="textarea" v-model="form.remarks"></el-input>
            </el-form-item>
        </el-form>
        <div class="handle-btn">
            <el-button size="small" @click="handleCancel">
                取消
            </el-button>
            <el-button type="primary" size="small" @click="handleSave">
                保存
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        const validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.form.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass');
                }
                callback();
            }
        };
        const validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.form.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            rules: {
                login_name: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
                user_name: [
                    { required: true, message: '请输入中文名', trigger: 'blur' },
                ],
                password: [
                    { validator: validatePass, trigger: 'blur' },
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' },
                ],
            },
            form: {},
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 保存
        handleSave() {
            // eslint-disable-next-line consistent-return
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.$axios
                        .post('/interfaceApi/datacenter/interface/user/save', this.form)
                        .then(res => {
                            if (res) {
                                this.$message.success('保存成功');
                                this.extr.closeCall();
                                window.hideToast();
                            }
                        }).catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else {
                    return false;
                }
            });
        },
        // 取消
        handleCancel() {
            window.hideToast();
        },

    },
    created() {
        if (this.extr.rowData) {
            this.form = this.extr.rowData;
        }
    },
    mounted() {},
};
</script>
<style lang="stylus">
.interfaceUser-addPop
    width 100%
    padding .2rem .4rem .2rem 0
    .handle-btn
        text-align center
        margin .1rem 0
</style>